import React, { useRef, useCallback, useState, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm, Controller } from 'react-hook-form'
import type { ReCAPTCHAProps } from 'react-google-recaptcha'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import StarRatings from 'components/StarRatings/StarRatings'
import LimitedTextarea from 'components/Result-Details/Limited-Text-Area'

import style from './Review-Form.module.scss'

import ReviewDates from '../Search/Filters/Dates/ReviewDate'

import { fetchBookingWithListingByAuthCode } from 'utils/salesforceAPI'
import { checkValidateEmail } from 'utils/validations'
import type {
  ListingObjectType,
  BookingObjectType,
  ContactObjectType,
} from 'utils/salesforceAPI'

type RetrievedData =
  | {
      listingObject: ListingObjectType | null
      bookingObject: BookingObjectType | null
      contactObject: ContactObjectType | null
    }
  | any

type ReviewFormInputs = {
  reviewedBy: string
  booking: string
  travelDate: string
  rating: number
  reviewSummary: string
  reviewDetail: string
  reviewedByEmail: string
  privateFeedback: string
}

type ReviewFormProps = {
  closeModal: () => void
  onSubmit: (data: any) => Promise<void>
  recaptchaChange: ReCAPTCHAProps['onChange']
  recaptchaError: boolean
}

const reviewHelper = (x: number) => {
  switch (x) {
    case 1:
      return 'Absolutely awful'
    case 2:
      return 'Barely acceptable'
    case 3:
      return 'Major issues'
    case 4:
      return 'Mostly fine'
    case 5:
      return 'Enjoyed it'
    default:
      return ''
  }
}

const ReviewForm: React.FC<ReviewFormProps> = ({
  onSubmit,
  recaptchaChange,
  recaptchaError,
  closeModal,
}) => {
  const router = useAugmentedRouter()
  const [inputData, setInputData] = useState<{
    reviewedBy: string
    reviewedByEmail: string
    booking: string
    checkInDate: string
  }>({
    reviewedBy: '',
    reviewedByEmail: '',
    booking: '',
    checkInDate: '',
  })
  const { register, handleSubmit, control, errors, setValue } =
    useForm<ReviewFormInputs>()

  useEffect(() => {
    const code = router.query?.bc ? (router.query.bc as string) : ''
    const fetchData = async () => {
      return await fetchBookingWithListingByAuthCode(code)
    }
    if (code) {
      fetchData()
        .then((data: RetrievedData) => {
          if (data?.bookingObject && data?.travelerObject) {
            setInputData({
              reviewedBy: data.travelerObject.FirstName,
              reviewedByEmail: data.bookingObject.Email,
              booking: data.bookingObject.bookingId,
              checkInDate: data.bookingObject.checkinDate,
            })
          }
        })
        .catch(console.error)
    }
  }, [router])

  useEffect(() => {
    if (inputData.checkInDate) {
      setValue(
        'travelDate',
        new Date(inputData.checkInDate).toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
      )
    }
  }, [inputData, setValue])

  const dateRef = useRef<HTMLDivElement | null>(null)

  const [hoverRating, setHoverRating] = useState(0)

  const handleHover = useCallback((starRating) => {
    setHoverRating(starRating)
  }, [])

  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value })
  }

  return (
    <form className={style.reviewForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={style.reviewFormTop}>
        <div className="formInputGroup">
          <label htmlFor="reviewedBy">First name</label>
          <input
            className={errors.reviewedBy && 'error'}
            name="reviewedBy"
            onChange={handleChange}
            ref={register({ required: true })}
            type="text"
            value={inputData.reviewedBy}
          />
          {errors.reviewedBy && <span>*Name is required</span>}
        </div>
        <div className="formInputGroup">
          <label htmlFor="reviewedByEmail">Email address</label>
          <input
            className={errors.reviewedByEmail && 'error'}
            name="reviewedByEmail"
            onChange={handleChange}
            ref={register({
              required: true,
              validate: (value) => checkValidateEmail(value),
            })}
            type="text"
            value={inputData.reviewedByEmail}
          />
          {errors.reviewedByEmail && <span>*Email is required</span>}
        </div>
        <div className={`formFlex`}>
          <div className="formInputGroupHalf">
            <label htmlFor="booking">Reservation #</label>
            <input
              className={errors.booking && 'error'}
              name="booking"
              onChange={handleChange}
              ref={register}
              type="text"
              value={inputData.booking}
            />
          </div>
          <div className="formInputGroupHalf">
            <label htmlFor="travelDate">Check-in date</label>
            <div ref={dateRef}>
              <Controller
                control={control}
                defaultValue={inputData.checkInDate}
                name="travelDate"
                render={({ onChange, value }) => (
                  <ReviewDates onChange={onChange} value={value} />
                )}
                rules={{ required: true }}
              />
              {errors.travelDate && (
                <span className={style.error}>*Check-in Date is required</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="formInputGroup">
        <p className={style.publicReviewFormBold}>Public review</p>
        <label htmlFor="rating">Rate your stay</label>
        <Controller
          control={control}
          defaultValue={0}
          name="rating"
          render={({ onChange, value }) => (
            <div>
              <StarRatings
                changeRating={onChange}
                name="rating"
                numberOfStars={5}
                onHover={handleHover}
                rating={value}
                starDimension="25px"
                starHoverColor="#F9A01F"
                starRatedColor="#F9A01F"
                starSpacing="4px"
                uniqueId="reviewForm"
              />
              <p className={style.reviewFormRatingHelper}>
                {reviewHelper(hoverRating) || reviewHelper(value)}
              </p>
            </div>
          )}
          rules={{ required: true, min: 1 }}
        />
        {errors.rating && (
          <span className={style.error}>*Rating is required</span>
        )}
      </div>
      <div className={`formInputGroup ${style.titleInput}`}>
        <label htmlFor="reviewSummary">Name your review</label>
        <LimitedTextarea
          hasErrors={errors.reviewSummary}
          limit={100}
          name="reviewSummary"
          ref={register({ required: true })}
        />
        {errors.reviewSummary && (
          <span className="error">*Review name is required</span>
        )}
      </div>
      <div className={`formInputGroup ${style.descriptionInput}`}>
        <label htmlFor="reviewDetail">How was your experience?</label>
        <LimitedTextarea
          hasErrors={errors.reviewDetail}
          limit={2500}
          name="reviewDetail"
          ref={register({ required: true })}
        />
        {errors.reviewDetail && (
          <span className="error">*Detail is required</span>
        )}
      </div>
      {/* Private feedback in progress */}
      <div className="formInputGroup">
        <label className={style.reviewFormBold} htmlFor="privateFeedback">
          Private feedback (optional)
        </label>
        <p className={style.privateFeedbackSub}>
          Share a private message to note any concerns or suggested improvements
          directly to the homeowner.
        </p>
        <div className={style.privateIconAndText}>
          <svg
            className={style.privateIcon}
            fill="#3e5a5e"
            height="16"
            viewBox="0 0 13 16"
            width="13"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0694 4.58387C11.097 3.37793 10.6279 2.21179 9.7689 1.34936C8.90892 0.48617 7.7307 0 6.5 0C5.27026 0 4.09195 0.48617 3.23205 1.34936C2.37207 2.21165 1.90299 3.3779 1.9315 4.58387V5.99906C0.766164 6.45274 0.00183659 7.55767 0 8.78608V12.9982C0 14.6561 1.37045 16 3.06098 16H9.93902C11.6295 16 13 14.6561 13 12.9982V8.77707C12.9991 7.54769 12.2338 6.44378 11.0694 5.99006L11.0694 4.58387ZM8.73327 2.39032C9.32559 2.97299 9.65764 3.76132 9.65673 4.58392V5.78444H3.34167V4.58392C3.34076 3.76132 3.67278 2.97299 4.26513 2.39032C4.85653 1.80856 5.66042 1.48114 6.49925 1.48024C7.33798 1.48114 8.14186 1.80856 8.73327 2.39032ZM11.5872 12.9984C11.5872 13.4269 11.4134 13.8382 11.1043 14.1412C10.7953 14.4443 10.3759 14.6148 9.93899 14.6148H3.05908C2.1485 14.6148 1.41085 13.8905 1.41085 12.9984V8.77728C1.41085 7.88433 2.1485 7.16095 3.05908 7.16095H9.93899C10.8496 7.16095 11.5872 7.88433 11.5872 8.77728V12.9984Z"
              fill="#3e5a5e"
            />
          </svg>
          <p className={style.privateIconText}>Private</p>
        </div>
        <textarea
          // className={errors.reviewDetail && 'error'}
          minLength={3}
          // ref={register({ required: true })}
          name="privateFeedback"
          ref={register({ required: false })}
        />
      </div>
      <label className={style.reviewFormLabel}>
        Reviews must be posted by verifiable guests. Read our complete review
        guidelines{' '}
        <a
          href="https://help.evolvevacationrental.com/s/article/What-are-evolves-review-guidelines-guest"
          rel="noopener noreferrer"
          target="_blank"
        >
          here.
        </a>
      </label>
      <ReCAPTCHA
        onChange={recaptchaChange}
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
      />
      <div className={style.formBtns} style={{ paddingTop: 20 }}>
        <button
          className="btn-primary"
          disabled={recaptchaError}
          id="write_review_submit_btn"
          type="submit"
        >
          Submit review
        </button>
        <button
          className={`btn btn-secondary ${style.cancelBtn}`}
          id="cancel_review_btn"
          onClick={() => closeModal()}
          type="button"
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

export default ReviewForm
